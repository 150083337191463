/* src/styles.css */
/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

/* Your additional styles go here */
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap");
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 1;
}

@import url("https://fonts.googleapis.com/css2?family=Borel&family=Lora:ital@1&display=swap");

@font-face {
    font-family: "BrittanySignature";
    src: url("../public/font/BrittanySignature-LjyZ.otf") format("opentype");
}
.home-text {
    font-family: "Lora", serif;
}
.home-heading {
    font-family: "BrittanySignature", sans-serif;
}
ul {
    list-style-type: "⭐ ";
}
.text-capitalizing ::first-letter {
    text-transform: uppercase !important;
}
/* Example CSS */
.disclaimer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.disclaimer-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 95%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: auto;
    margin-bottom: 2%;
    margin-top: 2%;
    max-height: 500px;
}

/* You might need to adjust these styles based on your design */
@keyframes sway {
    0% {
        transform: rotate(8deg);
    }
    50% {
        transform: rotate(-8deg);
    }
    100% {
        transform: rotate(8deg);
    }
}

.shake-on-hover {
    animation: sway 2.4s infinite;
    animation-timing-function: ease-in-out;
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    transform-origin: top;
}
.login_heading {
    font-family: "Abhaya Libre", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 46px;
}
.login_subheading {
    font-family: "Abhaya Libre", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 26px;
}
.underlinestyle {
    text-decoration: underline 3px double #4891e1;
}
