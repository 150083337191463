/*
-----------------------------------------------------------------------
	Imported CSS
-----------------------------------------------------------------------
*/
// Google fonts
@import url(https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800,900,|Libre+Baskerville:400,400i,700);
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

// @import url(bootstrap.min.css);
// @import url(icofont.css);
// @import url(animate.css);
// @import url(aos.css);
// @import url(swiper.min.css);
// @import url(plugin-collection.css);
