.timer-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer {
    font-size: 48px;
    padding: 20px;
    border-radius: 50%;
    text-align: center;
    transition: border-width 1s ease-in-out;
}
