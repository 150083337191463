/*
-----------------------------------------------------------------------
	Pricing CSS
-----------------------------------------------------------------------
*/

.pricing-area {
  &.pricing-default-area {
    padding: 125px 0 0;
    @media #{$lg-device} {
      padding: 90px 0 0;
    }
    @media #{$sm-device} {
      padding: 80px 0 0;
    }
    @media #{$xs-device} {
      padding: 80px 0 0;
    }
    .section-title {
      margin-bottom: 75px;
      @media #{$lg-device} {
        margin-bottom: 55px;
      }
      @media #{$xs-device} {
        margin-bottom: 45px;
      }
    }
  }
}

.pricing-items-style1 {
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0 0 125px;
  position: relative;
  @media #{$lg-device} {
    margin-bottom: 90px;
  }
  @media #{$xs-device} {
    border: none;
  }
  &:after {
    background-color: #e8e8e8;
    bottom: -130px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    @media #{$lg-device} {
      bottom: -100px;
    }
    @media #{$sm-device} {
      bottom: -80px;
    }
    @media #{$xs-device} {
      bottom: -70px;
    }
  }
  .pricing-item {
    border-right: 1px solid #e6e9ec;
    padding: 40px;

    @media #{$lg-device} {
      padding: 25px;
    }
    @media #{$xs-device} {
      border: 1px solid #e6e9ec;
      border-radius: 5px;
      margin-bottom: 30px;
    }
    .pricing-title {
      margin-bottom: 24px;
      h5 {
        color: $black-999;
        font-weight: $font-weight-normal;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    .pricing-amount {
      margin-bottom: 17px;
      h2 {
        color: $theme-color3;
        display: inline-block;
        font-size: 36px;
        line-height: 1;
        letter-spacing: 0;
        margin-bottom: 5px;
        sup {
          color: $theme-color3;
          font-size: 20px;
          position: relative;
          top: -12px;
          vertical-align: baseline;
        }
      }
      .period {
        color: $black;
        display: inline-block;
        font-size: 20px;
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        line-height: 1;
      }
    }
    .pricing-content {
      margin-bottom: 40px;
      .desc {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
      }
      .pricing-list {
        border-top: 1px dashed rgba($gray-ccc, 0.5);
        margin-bottom: 0;
        min-height: 196px;
        padding-top: 24px;
        li {
          color: $black;
          font-size: 16px;
          font-weight: $font-weight-normal;
          margin-bottom: 9px;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
          }
          @media (max-width: 767px) {
            font-size: 14px;
          }
          i {
            color: $theme-color3;
            font-size: 16px;
            display: inline-block;
            width: 27px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .pricing-footer {
      .btn-theme {
        &.btn-border {
          font-size: 14px;
          font-weight: $font-weight-bold;
          letter-spacing: 0;
          min-width: 198px;
          padding: 11px 30px;
          text-transform: uppercase;
          @media #{$lg-device} {
            min-width: 183.5px;
            padding: 11px 20px;
            text-align: center;
          }
          @media #{$xs-device} {
            min-width: 170px;
            padding: 9px 20px;
            text-align: center;
          }
          &:hover,
          &.active {
            background-color: $theme-color;
            color: $white;
          }
        }
      }
    }

    &.item-one {
      @media #{$md-device} {
        border-bottom: 1px solid #e6e9ec;
      }
    }
    &.item-two {
      @media #{$md-device} {
        border-bottom: 1px solid #e6e9ec;
      }
    }
    &.item-four {
      border-right: none;
      @media #{$xs-device} {
        border: 1px solid #e6e9ec;
        margin-bottom: 0;
      }
    }
  }
  &.noborder-line {
    &:after {
      display: none;
    }
  }
}
